<template>
  <div class="footer-main">
    <div class="footer-inr">
        <div class="footer-images-wrap">
          <a :href="banner.link" target="_blank" v-for="banner of banners">
            <img :src="getImgUrl(banner.image)" />
          </a>
        </div>
        <div class="online-members" elevation="0" v-if="$vuetify.breakpoint.width < 768">
            <h4>{{ $t("footer.onlineMembers") }}</h4> <label class="online-m-count"><span id="participantCount"> 0 </span></label>
        </div>
      <div class="social-dtl">
        <ul class="social-dtl">
          <li>
            <a :href="websiteLink" class="icon-website" target="_blank">
              <img src="../../assets/img/union.svg" />
            </a>
          </li>
          <li>
            <a :href="facebookLink" target="_blank">
              <img src="../../assets/img/fb.svg" />
            </a>
          </li>
          <li>
            <a class="icon-instagram" :href="instagramLink" target="_blank">
              <img src="../../assets/img/insta.svg" />
            </a>
          </li>
          <li>
            <a :href="tiktokLink" class="icon-website" target="_blank">
              <img src="../../assets/img/tictok.svg" width="30px" />
            </a>
          </li>
          <li>
            <a class="icon-wp" :href="whatsappLink" target="_blank">
              <img src="../../assets/img/whatsapp.svg" />
            </a>
          </li>
          <li>
            <a :href="youtubeLink" class="icon-youtube" target="_blank">
              <img src="../../assets/img/youtube.svg" />
            </a>
          </li>
        </ul>
        <!-- <v-btn :href="twitterLink" class="icon icon-instagram rounded mr-2" target="_blank">
              <img src="../../assets/twitter.png"/>
            </v-btn> -->


        <!--<v-btn right color="white" icon>
              <v-icon size="25" medium>mdi-cog</v-icon>
            </v-btn>-->
      </div>
    </div>
    <!-- </v-toolbar> -->
    <!-- {{ paymentInfo }} -->
    <v-dialog :value="paymentDialog" persistent max-width="600">
      <v-card>
        <v-card-title class="headline" v-html='$t("footer.modelTitle")'>
        </v-card-title>
        <v-card-text class="vcardtext">
          <v-alert v-if="paymentInfo?.has_failed_payment" dense outlined type="error">
            {{ paymentInfo?.failed_payment_reason ?? '' }}</v-alert>
          <br>
          <p style="font-size: 18px;">{{ $t("footer.modelNote") }} 714-334-5505</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green accent-4" class="green darken-4 green-gradient white--text" @click="
            dialog = false;
          $router.push('/payment-details');
          ">
            {{ $t("footer.paynow") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- {{ dailogue for audiobrigge }} -->
    <v-dialog :value="janusDialog" persistent max-width="600" content-class="janusDialog">
      <v-card>
        <v-card-title class="headline">{{ janus_error }}</v-card-title>
        <v-card-text class="vcardtext">
          <v-alert v-if="janus_error=='NotAllowedError: Permission denied'">
            <p class="rd-alert">You have issue with your MIC permission or MIC. First check your browser Mic Audio / Video permission settings.<br> {{ $t("footer.modelNote") }} 714-334-5505</p>
            <br><img src="../../assets/img/audio-permission.gif" />
          </v-alert>
          <v-alert v-if="janus_error=='NotFoundError: Requested device not found'">
            <p class="rd-alert"> You have a problem with your RED Phone or USB. Please plugged out and clean your USD then insert it again. <br> {{ $t("footer.modelNote") }} 714-334-5505</p>
            <br><img src="../../assets/img/clean-usb.gif" />
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green accent-4" class="green darken-4 green-gradient white--text" @click="janusDialogClose();">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EventBus from "../../bus/eventBus";
import { RECURRING_BY_CARD, MONTHLY_BY_CARD } from "@/constants/common";

export default {
  name: "Footer",
  data() {
    return {
      facebookLink: process.env.VUE_APP_FACEBOOK_LINK,
      instagramLink: process.env.VUE_APP_INSTAGRAM_LINK,
      youtubeLink: process.env.VUE_APP_YOUTUBE_LINK,
      twitterLink: process.env.VUE_APP_TWITTER_LINK,
      websiteLink: process.env.VUE_APP_WEBSITE_LINK,
      whatsappLink: process.env.VUE_APP_WHATSAPP_LINK,
      tiktokLink: process.env.VUE_APP_TIKTOK_LINK,
      banners: [],
      paymentDialog: false,
      janusDialog: false,
      janus_error: null,
    };
  },
  computed: {
    ...mapGetters({
      paymentInfo: "user/getPaymentStatus",
      user: "user/getUser",
    }),
  },
  async mounted() {
    EventBus.$on("PARTICIPANT_COUNT", function (count) {
      sessionStorage.setItem("onlineUsers", Number(count));
      console.log("onlineUsers=============>" + count);
      document.getElementById("participantCount").innerText = Number(count);
    });

    EventBus.$on("janusDialog", (error) => {
      this.janusDialog = true;
      this.janus_error = error;
    });

    await this.randomFooterBanner();

    this.$root.$on('updateFooterBanner', () => {
      setTimeout(() => {
        this.randomFooterBanner();
      }, 500);
    })

    this.$root.$on('checkPaymentStatus', () => {
      setTimeout(() => {
        this.checkPaymentStatus();
      }, 500);
    })
  },
  methods: {
    ...mapActions({
      getPaymentStatus: "user/getPaymentStatus"
    }),
    async randomFooterBanner() {
      var allBanners = [{ image: 'rdl-marketplace.gif', link: 'https://redlineusedautoparts.com/' }, { image: 'upload-inv.gif', link: 'https://inventory.redlineusedautoparts.com/?auth_details_user=' + this.user.id }, { image: 'Comms-Banner.png', link: 'https://www.goteleworks.com/' }, { image: 'FedericoUrias.jpg', link: 'javascript:void(0);' }]
      this.banners = this.getMultipleRandom(allBanners, 2);
    },
    getMultipleRandom(arr, num) {
      const shuffled = [...arr].sort(() => 0.5 - Math.random());
      return shuffled.slice(0, num);
    },
    janusDialogClose(){
        this.janusDialog = false;
        setTimeout(function () {
          window.location.reload();
         }, 1000);
    },
    getImgUrl(pet) {
      var images = require.context('../../assets/', false);
      return images('./' + pet)
    },
    async checkPaymentStatus() {
      await this.getPaymentStatus();
      console.log("this.paymentInfo: ", this.paymentInfo);
      if (
        this.paymentInfo &&
        !this.paymentInfo.is_subscribed &&
        !this.paymentInfo.is_on_grace_period
      ) {
        console.log("checkPaymentStatus is_subscribed: false, is_on_grace_period:false");
        this.paymentDialog = true;
        // this.$router.push({ name: "PaymentDetails" });
      } else if (
        this.paymentInfo &&
        this.paymentInfo.has_failed_payment &&
        !this.paymentInfo.is_on_grace_period &&
        [RECURRING_BY_CARD, MONTHLY_BY_CARD].includes(
          this.paymentInfo.payment_method
        )
      ) {
        console.log("checkPaymentStatus has_failed_payment: true, is_on_grace_period:false");
        this.paymentDialog = true;
        // this.$router.push({ name: "PaymentDetails" });
      } else if (
        this.paymentInfo &&
        this.paymentInfo.has_failed_payment &&
        this.paymentInfo.is_on_grace_period &&
        [RECURRING_BY_CARD, MONTHLY_BY_CARD].includes(
          this.paymentInfo.payment_method
        )) {
        console.log("checkPaymentStatus has_failed_payment: true, is_on_grace_period: true");
        this.paymentDialog = true;
      }
    }
  }
};
</script>
